var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c("general-function-popup", { staticClass: "action-require-popup" }, [
        _c(
          "div",
          { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
          [_c("h2", [_vm._v("특이사항 상세 내역")])]
        ),
        _c(
          "div",
          {
            staticClass: "contents",
            attrs: { slot: "contents" },
            slot: "contents",
          },
          [
            _c("ul", { staticClass: "action-status-list" }, [
              _c("li", [
                _c("span", [_vm._v("설비 코드")]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatEQPCode")({
                          eqpId: _vm.actionRequired.id,
                          mEqpId: _vm.actionRequired.mequipmentId,
                        })
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("li", [
                _c("span", [_vm._v("점검 항목")]),
                _c("span", [
                  _vm._v(
                    " [" +
                      _vm._s(
                        _vm.checkRequiredTypeName[
                          _vm.actionRequired["chkRequireType"]
                        ]
                      ) +
                      "] " +
                      _vm._s(_vm.actionRequired["chkHistItemName"]) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("ul", { staticClass: "request-action-list" }, [
              _c("li", [
                _c("div", { staticClass: "request" }, [
                  _c("h3", [_vm._v("특이사항")]),
                  _c(
                    "div",
                    { staticClass: "request-action-box" },
                    [
                      _c("div", { staticClass: "status-header" }, [
                        _c("span", {
                          staticClass: "status-color-box",
                          class: _vm.actionStatusClassName(_vm.actionRequired),
                        }),
                        _c("span", { staticClass: "text-overflow-ellipsis" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localDateYYYYMMDD")(
                                  _vm.actionRequired["reqDT"]
                                )
                              ) +
                              " " +
                              _vm._s(
                                _vm.actionRequired["requesterName"] || "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm.getFileInRscFiles(
                        _vm.actionRequired["actionRequiredRscFiles"],
                        "audio"
                      )
                        ? _c("audio-play", {
                            attrs: {
                              "audio-src": _vm.getFileInRscFiles(
                                _vm.actionRequired["actionRequiredRscFiles"],
                                "audio"
                              ).rscUrl,
                              descriptions: [
                                "리얼웨어에서 입력된 특이사항은 음성 녹음 파일로 확인할 수 있습니다.",
                              ],
                            },
                          })
                        : _c("label", [
                            _c("textarea", {
                              attrs: { readonly: "" },
                              domProps: {
                                value: _vm.actionRequired["reqDesc"],
                              },
                            }),
                          ]),
                      _c(
                        "div",
                        {
                          staticClass: "image-wrapper",
                          class: {
                            "none-image": !_vm.getFileInRscFiles(
                              _vm.actionRequired["actionRequiredRscFiles"],
                              "img"
                            ),
                          },
                        },
                        [
                          !_vm.getFileInRscFiles(
                            _vm.actionRequired["actionRequiredRscFiles"],
                            "img"
                          )
                            ? _c("p", [_vm._v(" 등록된 이미지가 없습니다. ")])
                            : _vm._e(),
                          _c("action-require-image-box", {
                            staticClass: "background-image",
                            attrs: {
                              "rsc-file": _vm.getFileInRscFiles(
                                _vm.actionRequired["actionRequiredRscFiles"],
                                "img"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "action" }, [
                  _c("div", { staticClass: "action-header" }, [
                    _c("h3", [_vm._v("조치")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "request-action-box",
                      class: {
                        "action-edit-mode": _vm.isEditMode,
                        "action-focus-mode": _vm.isFocusedTextarea,
                      },
                    },
                    [
                      !_vm.isEditMode
                        ? _c("div", { staticClass: "status-header" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("localDateYYYYMMDD")(
                                    _vm.actionRequired["actDT"]
                                  )
                                ) +
                                  " " +
                                  _vm._s(_vm.actionRequired["acterName"])
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.getFileInRscFiles(
                        _vm.actionRequired["actionRscFiles"],
                        "audio"
                      )
                        ? _c("audio-play", {
                            attrs: {
                              "audio-src": _vm.getFileInRscFiles(
                                _vm.actionRequired["actionRscFiles"],
                                "audio"
                              ).rscUrl,
                              descriptions: [
                                "리얼웨어에서 입력된 조치 내용은 음성 녹음 파일로 확인할 수 있습니다.",
                                "리얼웨어에서 음성 녹음 파일로 저장된 조치 내용은 수정할 수 없습니다.",
                              ],
                            },
                          })
                        : _c("label", [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.actionRequired["actDesc"],
                                  expression: "actionRequired['actDesc']",
                                },
                              ],
                              attrs: {
                                autocomplete: "off",
                                maxlength: 500,
                                readonly: !_vm.isEditMode,
                                placeholder: "조치 내용을 입력하세요.",
                              },
                              domProps: {
                                value: _vm.actionRequired["actDesc"],
                              },
                              on: {
                                focus: _vm.toggleIsFocusedTextarea,
                                blur: _vm.toggleIsFocusedTextarea,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.actionRequired,
                                    "actDesc",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                      _c(
                        "div",
                        {
                          staticClass: "image-wrapper",
                          class: {
                            "none-image": !_vm.getFileInRscFiles(
                              _vm.actionRequired["actionRscFiles"],
                              "img"
                            ),
                          },
                        },
                        [
                          !_vm.getFileInRscFiles(
                            _vm.actionRequired["actionRscFiles"],
                            "img"
                          )
                            ? [
                                _vm.isEditMode
                                  ? _c(
                                      "file-basic",
                                      {
                                        attrs: {
                                          id:
                                            "action-file-" +
                                            _vm.actionRequired.id,
                                          accept: ".jpg, .png",
                                          color: "dark",
                                          size: "s",
                                          width: 150,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSelectFile(
                                              $event,
                                              _vm.actionRequired
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("plus-icon", {
                                          attrs: { size: 16 },
                                        }),
                                        _vm._v(" 이미지 등록 "),
                                      ],
                                      1
                                    )
                                  : _c("p", [
                                      _vm._v("등록된 이미지가 없습니다."),
                                    ]),
                              ]
                            : [
                                _c("action-require-image-box", {
                                  staticClass: "background-image",
                                  attrs: {
                                    "rsc-file": _vm.getFileInRscFiles(
                                      _vm.actionRequired["actionRscFiles"],
                                      "img"
                                    ),
                                  },
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getFileInRscFiles(
                                        _vm.actionRequired["actionRscFiles"],
                                        "img"
                                      ).name
                                    )
                                  ),
                                ]),
                                _vm.isEditMode
                                  ? _c(
                                      "button-basic",
                                      {
                                        staticClass: "delete-button",
                                        attrs: { color: "dark", circle: "14" },
                                        on: {
                                          click: function ($event) {
                                            _vm.onDeletePhoto(
                                              _vm.actionRequired,
                                              _vm.getFileInRscFiles(
                                                _vm.actionRequired[
                                                  "actionRscFiles"
                                                ],
                                                "img"
                                              ).id
                                            )
                                          },
                                        },
                                      },
                                      [_c("close-icon")],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { attrs: { slot: "button" }, slot: "button" },
          [
            _vm.actionRequired.actDT && !_vm.isEditMode
              ? [
                  !_vm.getFileInRscFiles(
                    _vm.actionRequired["actionRscFiles"],
                    "audio"
                  )
                    ? _c(
                        "button-basic",
                        {
                          attrs: { color: "gray-border-1", width: 150 },
                          on: {
                            click: function ($event) {
                              return _vm.onClickEditAction(_vm.actionRequired)
                            },
                          },
                        },
                        [_vm._v(" 조치 내용 수정 ")]
                      )
                    : _vm._e(),
                  _c("button-basic", { on: { click: _vm.onClosePopup } }, [
                    _vm._v("닫기"),
                  ]),
                ]
              : [
                  _c(
                    "button-basic",
                    {
                      attrs: { color: "gray-border-1" },
                      on: { click: _vm.onClosePopup },
                    },
                    [_vm._v("취소")]
                  ),
                  _c(
                    "button-basic",
                    {
                      attrs: {
                        width: 150,
                        disabled: !_vm.isFilledAllRequiredField(
                          _vm.actionRequired["actDesc"]
                        ),
                        "loading-dot": _vm.isLoadingButton,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickSaveAction(_vm.actionRequired)
                        },
                      },
                    },
                    [_vm._v(" 조치 내용 저장 ")]
                  ),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }