var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectedTabIndex === 0,
            expression: "selectedTabIndex === 0",
          },
        ],
      },
      [_vm._m(0), _vm._m(1), _vm._m(2)]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectedTabIndex === 1,
            expression: "selectedTabIndex === 1",
          },
        ],
      },
      [_vm._m(3), _vm._m(4)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "status-label" }, [
      _c("span", { staticClass: "status-color-box" }),
      _c("span", [_vm._v("미점검")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "status-label" }, [
      _c("span", { staticClass: "status-color-box part-check" }),
      _c("span", [_vm._v("부분 점검")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "status-label" }, [
      _c("span", { staticClass: "status-color-box all-check" }),
      _c("span", [_vm._v("점검 완료")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "status-label" }, [
      _c("span", { staticClass: "status-color-box part-action" }),
      _c("span", [_vm._v("조치 대기")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "status-label" }, [
      _c("span", { staticClass: "status-color-box all-action" }),
      _c("span", [_vm._v("조치 완료")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }