<template>
  <section class="main-section bg-gray60 check-history-section">
    <main-section-header
      title="점검 이력"
      subtitle="선택한 설비의 점검 이력을 확인하고, 특이사항의 조치 내용을 입력할 수 있습니다."
    />
    <div class="container">
      <list-utility-menu
        :value="keyword"
        :hasSearchInput="true"
        placeholder="설비 코드로 검색하세요"
        @input="onChangeKeyword"
        @button="onMoveSearchedPage"
        @keyupenter="onMoveSearchedPage"
      >
        <drop-down-box
          slot="calendar"
          id="check-history-month"
          :value="selectedDT"
          :optionItemsData="searchDateOptions"
          @selectedOption="onSelectedDT"
        />
      </list-utility-menu>
      <Tab :tab-list="tabList" :selected-tab-index="selectedTabIndex" @setSelectedList="setSelectedList" />
      <div class="button-wrapper">
        <CheckStatusLabel :selectedTabIndex="selectedTabIndex" />
        <button-basic
          slot="button"
          color="gray-border-1"
          size="s"
          :width="120"
          :disabled="isDisabledExcelButton"
          @click="onDownloadExcel"
        >
          <img src="/images/excel.png" alt="download excel" />
          엑셀 다운로드
        </button-basic>
      </div>
      <calendar-table :thead-list="theadList" :selected-date="selectedDT" :list="calendarData">
        <li slot="contents-data" :class="`tab-index-${selectedTabIndex}`">
          <ul v-for="eqp in calendarData" :key="`contents-data-${eqp.id}`">
            <li
              v-for="(dailyHist, index) in eqp.simpleDailyHists"
              :key="`contents-data-${eqp.id}-${index}`"
              :class="dailyHistClassName(dailyHist)"
              :style="{ width: `calc((100% / ${lastDate}))` }"
            />
          </ul>
        </li>
        <ul slot="contents-label" v-for="(eqp, index) in calendarData" :key="`contents-label-${index}`">
          <li
            :style="{ width: `${theadList[0].widthNumPercent}%` }"
            :title="{ eqpId: eqp['id'], mEqpId: eqp['mequipmentId'] } | formatEQPCode"
          >
            <button role="link" @click="onMoveDetailPage(eqp, 'All')">
              {{ { eqpId: eqp['id'], mEqpId: eqp['mequipmentId'] } | formatEQPCode }}
            </button>
          </li>
          <li :style="{ width: `${theadList[1].widthNumPercent}%` }" :title="eqp.equipmentName">
            {{ eqp.equipmentName }}
          </li>
          <li
            :style="{ width: `${theadList[2].widthNumPercent}%` }"
            :title="`${eqp['mainManager'] || '-'}/${eqp['subManager'] || '-'}`"
          >
            {{ eqp['mainManager'] || '-' }}/{{ eqp['subManager'] || '-' }}
          </li>
        </ul>
        <p slot="no-list-description">{{ noListDescription }}</p>
      </calendar-table>
      <action-detail-list
        v-if="selectedTabIndex === 1 && calendarData"
        :calendar-data-length="calendarData.length"
        :action-requireds="actionRequireds"
        :is-requested-data-with-keyword="isRequestedDataWithKeyword"
        @reloadData="getDataByTab"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import DropDownBox from '@/component/dropDown/DropDownBox';
import ListUtilityMenu from '@/component/list/ListUtilityMenu';
import Tab from '@/component/tab/Tab';
import CalendarTable from '@/component/calendarTable/CalendarTable';
import getLastDate from '@/asset/js/getLastDate';
import ActionDetailList from '@/view/checkHistory/actionDetailList/ActionDetailList';
import CheckStatusLabel from '@/view/checkHistory/checkStatusLabel/CheckStatusLabel';
import checkHistoryExcel from '@/asset/js/downloadExcel/checkHistoryExcel';
import actionRequiredsExcel from '@/asset/js/downloadExcel/actionRequiredsExcel';

export default {
  name: 'CheckHistoryCalendar',
  props: [],
  data() {
    return {
      selectedDT: this.$options.filters.localDateYYYYMM(new Date()),
      keyword: '',
      selectedTabIndex: 0,
      tabList: [
        { name: '점검 현황', listLength: null },
        { name: '특이 사항', listLength: null },
      ],
      theadList: [
        { name: '설비 코드', widthNumPercent: 10 },
        { name: '설비명', widthNumPercent: 15 },
        { name: '점검 담당자 정/부', widthNumPercent: 10 },
        { name: '점검 현황', widthNumPercent: 65 },
      ],
      actionRequireds: null,
      checkHistoryCalendar: null,
      actionRequireCalendar: null,
      isRequestedDataWithKeyword: false,
    };
  },
  computed: {
    isDisabledExcelButton() {
      switch (this.selectedTabIndex) {
        case 0:
          return !this.checkHistoryCalendar;
        case 1:
          return !this.actionRequireCalendar || !this.actionRequireds;
        default:
          return true;
      }
    },
    searchDateOptions() {
      const date = new Date();
      const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      const options = [];

      for (let i = 0; i < 6; i++) {
        options.push(this.$options.filters.localDateYYYYMM(firstDate));
        firstDate.setMonth(firstDate.getMonth() - 1);
      }

      return options;
    },
    lastDate() {
      return getLastDate(this.selectedDT);
    },
    noListDescription() {
      if (this.isRequestedDataWithKeyword) {
        return '일치하는 검색 결과가 없습니다.';
      }
      return '등록된 설비가 없습니다.';
    },
    calendarData() {
      switch (this.selectedTabIndex) {
        case 0:
          return this.checkHistoryCalendar;
        case 1:
          return this.actionRequireCalendar;
        default:
          return null;
      }
    },
    startDT() {
      return this.$options.filters.localDateYYYYMMDD(this.selectedDT);
    },
    endDT() {
      return this.$options.filters.localDateYYYYMMDD(`${this.selectedDT}-${this.lastDate}`);
    },
  },
  created() {
    this.initRenderData();
  },
  mounted() {},
  watch: {
    $route() {
      this.initRenderData();
    },
  },
  methods: {
    ...mapActions('chkHists', [
      'GET_CHECK_HISTORY_CALENDAR',
      'GET_ACTION_REQUIRE_CALENDAR',
      'GET_ACTION_REQUIRE_DETAIL',
    ]),
    ...mapMutations('chkHists', ['SET_CHECK_HISTORY_DATE_RANGE']),
    initRenderData() {
      const selectedDT = this.$route.query.selectedDT;
      if (selectedDT) {
        this.selectedDT = this.$options.filters.localDateYYYYMM(selectedDT);
      }
      this.selectedTabIndex = Number(this.$route.query.tab) || 0;
      this.keyword = this.$route.query.keyword || '';
      this.getDataByTab();
    },
    onMoveSearchedPage() {
      this.$router
        .push({
          name: 'CheckHistoryCalendar',
          query: {
            tab: this.selectedTabIndex || undefined,
            selectedDT: this.selectedDT || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
    },
    onSelectedDT(selectedDT) {
      this.selectedDT = selectedDT;
      this.onMoveSearchedPage();
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    getDataByTab() {
      switch (this.selectedTabIndex) {
        case 0:
          this.getCheckHistoryCalendar();
          break;
        case 1:
          this.getActionRequireCalendar();
          this.getActionRequireds();
          break;
        default:
          this.$router.replace(`/notfound?path=${window.location.hash.split('#/')[1]}`).catch((err) => err);
          break;
      }
    },
    setSelectedList(tabName) {
      switch (tabName) {
        case '점검 현황':
          this.selectedTabIndex = 0;
          break;
        case '특이 사항':
          this.selectedTabIndex = 1;
          break;
        default:
          break;
      }
      this.onMoveSearchedPage();
    },
    dailyHistClassName(chkState) {
      switch (chkState) {
        case 'AllCheck':
          return 'all-check';
        case 'NotAllCheck':
          return 'part-check';
        default:
          return null;
      }
    },
    filterByKeyword(originList) {
      return originList.filter((actionRequired) => {
        const code = this.$options.filters.formatEQPCode({
          mEqpId: actionRequired['mequipmentId'],
          eqpId: actionRequired['id'],
        });

        return code.toLowerCase().includes(this.keyword.toLowerCase());
      });
    },
    async getActionRequireds() {
      try {
        this.isRequestedDataWithKeyword = !!this.keyword;
        this.actionRequireds = null;
        const actionRequireds = await this.GET_ACTION_REQUIRE_DETAIL([this.startDT, this.endDT]);

        actionRequireds.sort((a, b) => {
          return a.chkHistItemState > b.chkHistItemState ? -1 : a.chkHistItemState < b.chkHistItemState ? 1 : 0;
        });

        this.actionRequireds = this.filterByKeyword(actionRequireds);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getCheckHistoryCalendar() {
      try {
        this.isRequestedDataWithKeyword = !!this.keyword;
        this.checkHistoryCalendar = null;
        const checkHistoryCalendar = await this.GET_CHECK_HISTORY_CALENDAR([this.startDT, this.endDT, this.keyword]);
        this.setSimpleDailyHistory(checkHistoryCalendar, 'chkState');
        this.checkHistoryCalendar = this.filterByKeyword(checkHistoryCalendar);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getActionRequireCalendar() {
      try {
        this.actionRequireCalendar = null;
        const actionRequireCalendar = await this.GET_ACTION_REQUIRE_CALENDAR([this.startDT, this.endDT, this.keyword]);
        this.setSimpleDailyHistory(actionRequireCalendar, 'notTakenActionCnt');
        this.actionRequireCalendar = this.filterByKeyword(actionRequireCalendar);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    setSimpleDailyHistory(calendar, baseValue) {
      calendar.forEach((eqp) => {
        let prevDate = 0;
        eqp.simpleDailyHists = [];

        eqp.dailyHists.forEach((dailyHist) => {
          const date = new Date(dailyHist.baseDay).getDate();
          let value = dailyHist[baseValue];

          if (typeof value === 'number') {
            value = value > 0 ? 'NotAllCheck' : 'AllCheck';
          }

          for (let i = prevDate + 1; i < date; i++) {
            eqp.simpleDailyHists.push(null);
          }

          prevDate = date;
          eqp.simpleDailyHists.push(value);
        });

        for (let i = prevDate + 1; i <= this.lastDate; i++) {
          eqp.simpleDailyHists.push(null);
        }
      });
    },
    onDownloadExcel() {
      let excelName;
      switch (this.selectedTabIndex) {
        case 0:
          excelName = `CheckHistory_Summary_${this.selectedDT}${this.keyword ? '_' + this.keyword : ''}.xlsx`;
          checkHistoryExcel(excelName, this.selectedDT, this.lastDate, this.checkHistoryCalendar);
          break;
        case 1:
          excelName = `CheckHistory_ReportedList_${this.selectedDT}${this.keyword ? '_' + this.keyword : ''}.xlsx`;
          actionRequiredsExcel(
            excelName,
            this.selectedDT,
            this.lastDate,
            this.actionRequireCalendar,
            this.actionRequireds,
          );
          break;
        default:
          break;
      }
    },
    onMoveDetailPage(eqp) {
      this.$router
        .push({
          name: 'CheckHistoryCalendarDetail',
          params: { eId: eqp.id },
          query: {
            tab: this.selectedTabIndex || undefined,
            selectedDT: this.selectedDT || undefined,
            meId: eqp['mequipmentId'] || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
      this.SET_CHECK_HISTORY_DATE_RANGE({ selectedDT: this.selectedDT });
    },
  },
  components: {
    CheckStatusLabel,
    ActionDetailList,
    CalendarTable,
    Tab,
    ListUtilityMenu,
    DropDownBox,
    MainSectionHeader,
  },
};
</script>

<style scoped lang="scss">
@import 'CheckHistoryCalendar';
</style>
