<template>
  <div class="action-detail-list">
    <h3>특이 사항 상세 내역</h3>
    <ul>
      <li v-for="(actionRequired, index) in actionRequireds" :key="`action-detail-${index}`">
        <div>
          <div class="eqp-code">
            {{ { eqpId: actionRequired.id, mEqpId: actionRequired.mequipmentId } | formatEQPCode }}
          </div>
          <div>
            <p>
              <span class="status-color-box" :class="actionStatusClassName(actionRequired)" />
              <span class="text-overflow-ellipsis">
                {{ actionRequired.reqDesc }}
              </span>
            </p>
            <p>
              <span :title="actionRequired.requesterName || '-'">
                {{ actionRequired.reqDT | localDateYYYYMMDD }} 보고자
                {{ actionRequired.requesterName || '-' }}
              </span>
              <span v-if="actionRequired.actDT" :title="actionRequired.acterName">
                {{ actionRequired.actDT | localDateYYYYMMDD }} 조치자 {{ actionRequired.acterName }}
              </span>
            </p>
          </div>
        </div>
        <button-basic
          :color="actionButtonColor(actionRequired)"
          size="s"
          :width="120"
          @click="onClickActionStatus(actionRequired)"
        >
          {{ actionStateType[actionRequired.actDT ? 'Action' : 'NotTakenAction'] }}
        </button-basic>
      </li>
    </ul>
    <div v-show="!actionRequireds || actionRequireds.length === 0" class="list-data-none">
      <loading-bar v-if="!actionRequireds" />
      <p v-else-if="actionRequireds.length === 0">{{ noListDescription }}</p>
    </div>
    <action-require-popup
      :is-show="showedPopupName === 'ActionStatusPopup'"
      :actionRequired="selectedActionRequired"
      @close="onCloseActionPopup"
      @save="$emit('reloadData')"
    />
  </div>
</template>

<script>
import LoadingBar from '@/component/loadingBar/loadingBar';
import ActionRequirePopup from '@/component/popup/actionRequirePopup/ActionRequirePopup';
import actionStateType from '@/const/actionStateType';

export default {
  name: 'ActionDetailList',
  props: ['actionRequireds', 'isRequestedDataWithKeyword', 'calendarDataLength'],
  data() {
    return {
      actionStateType: actionStateType,
      showedPopupName: '',
      selectedActionRequired: {},
    };
  },
  computed: {
    noListDescription() {
      if (this.calendarDataLength > 0) {
        return this.isRequestedDataWithKeyword ? '특이사항이 없습니다.' : '선택하신 기간에 특이 사항이 없습니다.';
      } else {
        return this.isRequestedDataWithKeyword ? '일치하는 검색 결과가 없습니다.' : '등록된 설비가 없습니다.';
      }
    },
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    actionStatusClassName(actionRequired) {
      return actionRequired.actDT ? 'all-action' : 'part-action';
    },
    actionButtonColor(actionRequired) {
      return actionRequired.actDT ? 'blue-border-1' : 'orange-border-1';
    },
    onClickActionStatus(actionRequired) {
      this.togglePopup('ActionStatusPopup');
      this.selectedActionRequired = JSON.parse(JSON.stringify(actionRequired));
    },
    onCloseActionPopup() {
      this.$emit('reloadActionDetailList');
      this.togglePopup();
    },
  },
  components: { ActionRequirePopup, LoadingBar },
};
</script>

<style scoped lang="scss">
@import 'ActionDetailList';
</style>
