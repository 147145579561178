<template>
  <general-function-popup class="action-require-popup" v-if="isShow">
    <div slot="title" class="title">
      <h2>특이사항 상세 내역</h2>
    </div>
    <div slot="contents" class="contents">
      <ul class="action-status-list">
        <li>
          <span>설비 코드</span>
          <span>
            {{ { eqpId: actionRequired.id, mEqpId: actionRequired.mequipmentId } | formatEQPCode }}
          </span>
        </li>
        <li>
          <span>점검 항목</span>
          <span>
            [{{ checkRequiredTypeName[actionRequired['chkRequireType']] }}] {{ actionRequired['chkHistItemName'] }}
          </span>
        </li>
      </ul>
      <ul class="request-action-list">
        <li>
          <div class="request">
            <h3>특이사항</h3>
            <div class="request-action-box">
              <div class="status-header">
                <span class="status-color-box" :class="actionStatusClassName(actionRequired)" />
                <span class="text-overflow-ellipsis">
                  {{ actionRequired['reqDT'] | localDateYYYYMMDD }}
                  {{ actionRequired['requesterName'] || '-' }}
                </span>
              </div>
              <audio-play
                v-if="getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'audio')"
                :audio-src="getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'audio').rscUrl"
                :descriptions="['리얼웨어에서 입력된 특이사항은 음성 녹음 파일로 확인할 수 있습니다.']"
              />
              <label v-else>
                <textarea readonly :value="actionRequired['reqDesc']" />
              </label>
              <div
                class="image-wrapper"
                :class="{ 'none-image': !getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'img') }"
              >
                <p v-if="!getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'img')">
                  등록된 이미지가 없습니다.
                </p>
                <action-require-image-box
                  class="background-image"
                  :rsc-file="getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'img')"
                />
              </div>
            </div>
          </div>
          <div class="action">
            <div class="action-header">
              <h3>조치</h3>
            </div>
            <div
              class="request-action-box"
              :class="{ 'action-edit-mode': isEditMode, 'action-focus-mode': isFocusedTextarea }"
            >
              <div class="status-header" v-if="!isEditMode">
                <span>{{ actionRequired['actDT'] | localDateYYYYMMDD }} {{ actionRequired['acterName'] }}</span>
              </div>
              <audio-play
                v-if="getFileInRscFiles(actionRequired['actionRscFiles'], 'audio')"
                :audio-src="getFileInRscFiles(actionRequired['actionRscFiles'], 'audio').rscUrl"
                :descriptions="[
                  '리얼웨어에서 입력된 조치 내용은 음성 녹음 파일로 확인할 수 있습니다.',
                  '리얼웨어에서 음성 녹음 파일로 저장된 조치 내용은 수정할 수 없습니다.',
                ]"
              />
              <label v-else>
                <textarea
                  v-model="actionRequired['actDesc']"
                  autocomplete="off"
                  :maxlength="500"
                  :readonly="!isEditMode"
                  placeholder="조치 내용을 입력하세요."
                  @focus="toggleIsFocusedTextarea"
                  @blur="toggleIsFocusedTextarea"
                />
              </label>
              <div
                class="image-wrapper"
                :class="{ 'none-image': !getFileInRscFiles(actionRequired['actionRscFiles'], 'img') }"
              >
                <template v-if="!getFileInRscFiles(actionRequired['actionRscFiles'], 'img')">
                  <file-basic
                    v-if="isEditMode"
                    :id="`action-file-${actionRequired.id}`"
                    accept=".jpg, .png"
                    color="dark"
                    size="s"
                    :width="150"
                    @change="onSelectFile($event, actionRequired)"
                  >
                    <plus-icon :size="16" />
                    이미지 등록
                  </file-basic>
                  <p v-else>등록된 이미지가 없습니다.</p>
                </template>
                <template v-else>
                  <action-require-image-box
                    class="background-image"
                    :rsc-file="getFileInRscFiles(actionRequired['actionRscFiles'], 'img')"
                  />
                  <span>{{ getFileInRscFiles(actionRequired['actionRscFiles'], 'img').name }}</span>
                  <button-basic
                    v-if="isEditMode"
                    color="dark"
                    circle="14"
                    @click="
                      onDeletePhoto(actionRequired, getFileInRscFiles(actionRequired['actionRscFiles'], 'img').id)
                    "
                    class="delete-button"
                  >
                    <close-icon />
                  </button-basic>
                </template>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div slot="button">
      <template v-if="actionRequired.actDT && !isEditMode">
        <button-basic
          v-if="!getFileInRscFiles(actionRequired['actionRscFiles'], 'audio')"
          color="gray-border-1"
          :width="150"
          @click="onClickEditAction(actionRequired)"
        >
          조치 내용 수정
        </button-basic>
        <button-basic @click="onClosePopup">닫기</button-basic>
      </template>
      <template v-else>
        <button-basic color="gray-border-1" @click="onClosePopup">취소</button-basic>
        <button-basic
          :width="150"
          :disabled="!isFilledAllRequiredField(actionRequired['actDesc'])"
          :loading-dot="isLoadingButton"
          @click="onClickSaveAction(actionRequired)"
        >
          조치 내용 저장
        </button-basic>
      </template>
    </div>
  </general-function-popup>
</template>
<script>
import { mapActions } from 'vuex';
import checkHistoryItemState from '@/const/checkHistoryItemState';
import checkRequiredType from '@/const/checkRequiredType';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import fileUpload from '@/asset/js/fileUpload';
import ActionRequireImageBox from '@/component/popup/actionRequirePopup/actionRequireImageBox/ActionRequireImageBox';
import AudioPlay from '@/component/audioPlay/AudioPlay';
import getFileInRscFiles from '@/asset/js/getFileInRscFiles';

export default {
  name: 'ActionRequirePopup',
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    actionRequired: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkRequiredTypeName: checkRequiredType,
      checkHistoryItemStateName: checkHistoryItemState,
      imageUrl: '',
      allFilename: '',
      fileBase64: '',
      selectedActionRequired: {},
      editingActionDescription: '',
      isEditMode: true,
      isFocusedTextarea: false,
      showedPopupName: '',
      expandingImageSrc: '',
      isLoadingButton: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    fileBase64() {
      if (this.fileBase64) {
        this.postActionRscFiles({
          name: this.allFilename,
          bs64Strfile: this.fileBase64,
          chkObjectId: this.actionRequired.id,
          type: 'ActImg',
        });
      }
    },
    isShow() {
      if (this.isShow) {
        this.isEditMode = !this.actionRequired.actDT;
      }
    },
  },
  methods: {
    ...mapActions('actionRequireds', ['PUT_ACTION_REQUIREDS', 'POST_ACTION_REQUIREDS']),
    ...mapActions('actionRscFiles', ['POST_ACTION_RSC_FILES', 'DELETE_ACTION_RSC_FILES']),
    getFileInRscFiles(rscFiles, typeValue) {
      return getFileInRscFiles(rscFiles, typeValue);
    },
    toggleIsFocusedTextarea() {
      this.isFocusedTextarea = !this.isFocusedTextarea;
    },
    isFilledAllRequiredField(description) {
      return description;
    },
    actionStatusClassName(actionRequire) {
      return actionRequire.actDT ? 'all-action' : 'part-action';
    },
    onSelectFile(e, actionRequired) {
      fileUpload(this, e.target, true, false);
      this.selectedActionRequired = actionRequired;
    },
    onClosePopup() {
      this.$emit('close');
    },
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    onDeletePhoto(actionRequired, rscFileId) {
      actionRequired.rscFileIds = [];
      actionRequired.actionRscFiles = actionRequired.actionRscFiles.map((file) => {
        return file.id !== rscFileId;
      });
    },
    onClickEditAction() {
      this.isEditMode = true;
    },
    createActionReqBody(actionRequired) {
      const action = { actDesc: actionRequired['actDesc'].trim() };
      if (actionRequired['rscFileIds'] && actionRequired['rscFileIds'].length > 0) {
        action.rscFileIds = actionRequired['rscFileIds'];
      } else if (getFileInRscFiles(actionRequired['actionRscFiles'], 'img')) {
        action.rscFileIds = [getFileInRscFiles(actionRequired['actionRscFiles'], 'img').id];
      } else {
        action.rscFileIds = [];
      }
      return action;
    },
    async onClickSaveAction(actionRequired) {
      try {
        this.isLoadingButton = true;
        const action = this.createActionReqBody(actionRequired);
        let resData = {};
        if (actionRequired['actDT']) {
          resData = await this.putActionRequireds(actionRequired, action);
        } else {
          resData = await this.postActionRequireds(actionRequired, action);
        }
        actionRequired['actDT'] = resData.actDT;
        actionRequired['acterName'] = resData.acter;
        this.$emit('save');
        this.onClosePopup(true);
      } finally {
        this.isLoadingButton = false;
      }
    },
    async putActionRequireds(actionRequired, action) {
      return await this.PUT_ACTION_REQUIREDS([actionRequired['actionRequireId'], action]);
    },
    async postActionRequireds(actionRequired, action) {
      return await this.POST_ACTION_REQUIREDS([actionRequired['actionRequireId'], action]);
    },
    async postActionRscFiles(rscFile) {
      try {
        const fileId = await this.POST_ACTION_RSC_FILES(rscFile);
        this.selectedActionRequired.rscFileIds = [{ id: fileId }];
        this.selectedActionRequired.actionRscFiles.unshift({
          id: fileId,
          rscUrl: this.imageUrl,
          name: this.allFilename,
          type: 'img',
        });
      } finally {
        this.allFilename = '';
        this.fileBase64 = '';
      }
    },
  },
  components: { AudioPlay, ActionRequireImageBox, GeneralFunctionPopup },
};
</script>
<style scoped lang="scss">
@import 'ActionRequirePopup';
</style>
