<template>
  <div>
    <div v-show="selectedTabIndex === 0">
      <span class="status-label">
        <span class="status-color-box" />
        <span>미점검</span>
      </span>
      <span class="status-label">
        <span class="status-color-box part-check" />
        <span>부분 점검</span>
      </span>
      <span class="status-label">
        <span class="status-color-box all-check" />
        <span>점검 완료</span>
      </span>
    </div>
    <div v-show="selectedTabIndex === 1">
      <span class="status-label">
        <span class="status-color-box part-action" />
        <span>조치 대기</span>
      </span>
      <span class="status-label">
        <span class="status-color-box all-action" />
        <span>조치 완료</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckStatusLabel',
  props: ['selectedTabIndex'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'CheckStatusLabel';
</style>
