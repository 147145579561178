import XLSX from 'xlsx-color';
import { formatEQPCode } from '@/util/filters';
import { titleStyle, labelHeaderStyle, tableHeaderStyle } from '@/asset/js/downloadExcel/common/commonStyle';
import setTableStyle from '@/asset/js/downloadExcel/common/setTableStyle';
import setCalendarDateCell from '@/asset/js/downloadExcel/common/setCalendarDateCell';

export default (excelName, selectedDT, lastDate, calendar) => {
  const CIRCLE = 'O';
  const TRIANGLE = '△';
  const TABLE_TITLE = [{ 0: `${selectedDT} 점검 이력` }, {}];
  const TABLE_LABEL = [
    { 0: '표시 방식', 1: '' },
    { 0: '미점검', 1: '' },
    { 0: '부분 점검', 1: TRIANGLE },
    { 0: '점검 완료', 1: CIRCLE },
    {},
  ];
  const TABLE_DATA = [
    {
      0: '설비코드',
      1: '설비명',
      2: '점검 담당자 정/부',
    },
  ];
  const TABLE_LEFT_DATA_LENGTH = Object.keys(TABLE_DATA[0]).length;

  setCalendarDateCell(TABLE_DATA, TABLE_LEFT_DATA_LENGTH, selectedDT, lastDate);

  calendar.forEach((calendarItem, i) => {
    TABLE_DATA[i + 1] = {};
    TABLE_DATA[i + 1][0] = formatEQPCode({
      mEqpId: calendarItem['mequipmentId'],
      eqpId: calendarItem['id'],
    });
    TABLE_DATA[i + 1][1] = calendarItem.equipmentName;
    TABLE_DATA[i + 1][2] = `${calendarItem.mainManager}/${calendarItem.subManager || '-'}`;

    calendarItem.simpleDailyHists.forEach((histData, j) => {
      let changedData = '';
      switch (histData) {
        case 'AllCheck':
          changedData = CIRCLE;
          break;
        case 'NotAllCheck':
          changedData = TRIANGLE;
          break;
        default:
          changedData = '';
          break;
      }

      TABLE_DATA[i + 1][j + TABLE_LEFT_DATA_LENGTH] = changedData;
    });
  });

  const EXCEL_FILE = XLSX.utils.book_new(); // 엑셀 파일 생성
  const WORK_SHEET = XLSX.utils.json_to_sheet([...TABLE_TITLE, ...TABLE_LABEL, ...TABLE_DATA], {
    skipHeader: true,
  }); // 시트 생성 (worksheet) 및 데이터 삽입

  WORK_SHEET['!cols'] = [{ wch: 15 }, { wch: 15 }, { wch: 20 }];
  WORK_SHEET.A1.s = titleStyle;
  setTableStyle({
    workSheet: WORK_SHEET,
    table: TABLE_LABEL,
    headerStyle: labelHeaderStyle,
    startTableRowNum: TABLE_TITLE.length,
    cellBolderValues: [CIRCLE, TRIANGLE],
  });
  setTableStyle({
    workSheet: WORK_SHEET,
    table: TABLE_DATA,
    headerStyle: tableHeaderStyle,
    startTableRowNum: TABLE_TITLE.length + TABLE_LABEL.length,
    leftDataLength: TABLE_LEFT_DATA_LENGTH,
    cellBolderValues: [CIRCLE, TRIANGLE],
  });

  XLSX.utils.book_append_sheet(EXCEL_FILE, WORK_SHEET, 'sheet1'); // 엑셀 파일에 시트 추가
  XLSX.writeFile(EXCEL_FILE, excelName); // 엑셀 다운로드
};
