var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-detail-list" },
    [
      _c("h3", [_vm._v("특이 사항 상세 내역")]),
      _c(
        "ul",
        _vm._l(_vm.actionRequireds, function (actionRequired, index) {
          return _c(
            "li",
            { key: "action-detail-" + index },
            [
              _c("div", [
                _c("div", { staticClass: "eqp-code" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatEQPCode")({
                          eqpId: actionRequired.id,
                          mEqpId: actionRequired.mequipmentId,
                        })
                      ) +
                      " "
                  ),
                ]),
                _c("div", [
                  _c("p", [
                    _c("span", {
                      staticClass: "status-color-box",
                      class: _vm.actionStatusClassName(actionRequired),
                    }),
                    _c("span", { staticClass: "text-overflow-ellipsis" }, [
                      _vm._v(" " + _vm._s(actionRequired.reqDesc) + " "),
                    ]),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      { attrs: { title: actionRequired.requesterName || "-" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("localDateYYYYMMDD")(actionRequired.reqDT)
                            ) +
                            " 보고자 " +
                            _vm._s(actionRequired.requesterName || "-") +
                            " "
                        ),
                      ]
                    ),
                    actionRequired.actDT
                      ? _c(
                          "span",
                          { attrs: { title: actionRequired.acterName } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("localDateYYYYMMDD")(
                                    actionRequired.actDT
                                  )
                                ) +
                                " 조치자 " +
                                _vm._s(actionRequired.acterName) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "button-basic",
                {
                  attrs: {
                    color: _vm.actionButtonColor(actionRequired),
                    size: "s",
                    width: 120,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onClickActionStatus(actionRequired)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.actionStateType[
                          actionRequired.actDT ? "Action" : "NotTakenAction"
                        ]
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.actionRequireds || _vm.actionRequireds.length === 0,
              expression: "!actionRequireds || actionRequireds.length === 0",
            },
          ],
          staticClass: "list-data-none",
        },
        [
          !_vm.actionRequireds
            ? _c("loading-bar")
            : _vm.actionRequireds.length === 0
            ? _c("p", [_vm._v(_vm._s(_vm.noListDescription))])
            : _vm._e(),
        ],
        1
      ),
      _c("action-require-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ActionStatusPopup",
          actionRequired: _vm.selectedActionRequired,
        },
        on: {
          close: _vm.onCloseActionPopup,
          save: function ($event) {
            return _vm.$emit("reloadData")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }